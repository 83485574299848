/* eslint-disable max-statements */
import { useEffect, useRef, useState } from "react";
import { ClientLottie } from "../ClientLottie";
// ref: https://lottiefiles.com/jp/free-animation/star-animation-71I449nZtc
// eslint-disable-next-line import/extensions
import favoriteLottieJson from "./favorite.lottie.json";
import { useDictionary } from "~/libs/i18n/context/DictionaryProvider";
import clsx from "clsx";

const FAVORITE_LOTTIE_JSON_FRAMES_COUNT = 30;

export type FavoriteButtonProps = {
  readonly isFavorite: boolean;
  readonly className?: string;
  readonly size?: number;
  readonly onClick: () => void;
};

// TODO: hover時に「お気に入りに追加」的なtooltip出したい。ただshadcnuiのツールチップは大きさが変わってしまって駄目だったな
// HACK: isFavoriteがtrueな時の初期読み込み時に、一瞬だけisFavoriteがfalseな時の状態がチラ見えする挙動を、その一瞬を非表示にすることで回避してしてしまっている
export function FavoriteButton({
  isFavorite,
  className,
  size,
  onClick,
}: FavoriteButtonProps): JSX.Element {
  const dict = useDictionary();
  const isFirstRenderRef = useRef(true);
  const isSecondRenderRef = useRef(false);

  const isFirstRender = isFirstRenderRef.current;
  const isSecondRender = isSecondRenderRef.current;

  // eslint-disable-next-line react/hook-use-state, @typescript-eslint/no-unused-vars
  const [_, setCnt] = useState(0);

  useEffect(() => {
    isFirstRenderRef.current = false;
    setTimeout(() => {
      isSecondRenderRef.current = true;
      setCnt((prev) => prev + 1);
    }, 0);
  }, []);

  useEffect(() => {
    if (isSecondRender) {
      isSecondRenderRef.current = false;
    }
  }, [isSecondRender]);

  const play = isFirstRender || isSecondRender ? false : isFavorite;
  const goTo =
    (isFirstRender && isFavorite) || (isSecondRender && isFavorite)
      ? FAVORITE_LOTTIE_JSON_FRAMES_COUNT
      : 0;

  return (
    <button
      type="button"
      onClick={onClick}
      aria-label={
        isFavorite
          ? dict.remove_from_x(dict.favorite)
          : dict.add_to_x(dict.favorite)
      }
      aria-pressed={isFavorite}
      className={className}
      style={
        isFirstRender
          ? {
              visibility: "hidden",
            }
          : {}
      }
    >
      <div className={clsx("rounded-full", !isFavorite && "bg-muted/50")}>
        <div className="transition hover:scale-110">
          <ClientLottie
            fallback={
              <div
                style={{
                  height: size,
                  width: size,
                }}
              />
            }
            animationData={favoriteLottieJson}
            rendererSettings={{}}
            loop={false}
            style={{ width: size, height: size, color: "blue" }}
            play={play}
            goTo={goTo}
          />
        </div>
      </div>
    </button>
  );
}
